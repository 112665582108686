/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { TransitionImage, TransitionStaticImage } from "StaticPages/Components/TransitionImage";
import { OpeningShutter } from "StaticPages/Components/OpeningShutter";
import { Transition } from "react-transition-group";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Button, Grid, Hidden, List, ListItem, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { css } from "@mui/material";
import { FadeAndSlideScrollTriggerAnimation } from "StaticPages/Components/FadeAndSlideScriollTriggerAnimation";
import { ServiceItemContainer } from "StaticPages/Components/Top/ServiceItemContainer";
import { SectionTitle } from "StaticPages/Components/Top/SectionTitle";
import { LinkButton } from "StaticPages/Components/LinkButton";
import { DateTime } from "luxon";
import { useWindowSize } from "mui-extensions/src/Hooks/useWindowSize";
import { SkewScrollTriggerAnimation } from "StaticPages/Components/SkewScrollTriggerAnimation";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import { BIDnaviBunner } from "StaticPages/Components/Commons/BIDnaviBunner";
import { SAMMEBunner } from "StaticPages/Components/Commons/SAMMEBunner";
import { SAMMElightBunner } from "StaticPages/Components/Commons/SAMMElightBunner";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { ViewMoreButton } from "StaticPages/Components/Commons/ViewMoreButton";
import Axios from "axios";

export default () => {
    const [open, setOpen] = useState(false);
    useEffect(() => setOpen(true), []);

    return <>
        <Helmet title="VISUALIZ INC" />
        <Layout>
            <OpeningShutter />
            <Transition in={open} timeout={2600}>
                {
                    state => <div
                        style={{
                            transition: "all 3s cubic-bezier(0.24, 0.21, 0.15, 1.03)",
                            transform: state === "entered" ? "translateY(0px)" : "translateY(20px)",
                            opacity: state === "entered" ? 1 : 0,
                        }}
                    >
                        <Main />
                    </div>
                }
            </Transition>
        </Layout>
    </>;
};

const Main = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const size = useWindowSize(500);
    return (
        <main>
            <Box >
                <Box
                    style={{
                        minHeight: "300px",
                        height: `${size.innerHeight * 0.58}px`
                    }}
                >
                    <TransitionStaticImage
                        parallaxSlideLength={50}
                    >
                        {style => <img
                            src={require("../../images/top.jpg").default}
                            placeholder="blurred"
                            alt="top"
                            style={style as any}
                        />}
                    </TransitionStaticImage>
                </Box>

                <Box
                    px={{ xs: 1, sm: 3, }}
                    py={{ xs: 1, sm: 3, md: 4, lg: 5, xl: 6 }}
                    minHeight={`${size.innerHeight * 0.42}px`}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Box>
                        <Box>
                            <SkewAnimation
                                delay={3400}
                                in
                            >
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        display: "inline",
                                        lineHeight: { xs: 1.8, sm: 2.2 },
                                        letterSpacing: {
                                            xs: "0.07em",
                                            sm: "0.17em"
                                        }
                                    }}
                                >
                                    {t("みえなかったものをみにいこう")}
                                    <Hidden mdDown>
                                        &nbsp; &nbsp;
                                    </Hidden>
                                    <Hidden mdUp>
                                        <br />
                                    </Hidden>
                                    {t("みえはじめたものをひろめよう")}
                                </Typography>
                            </SkewAnimation>

                            <SkewAnimation
                                delay={3600}
                                in
                            >
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        lineHeight: { xs: 1.8, sm: 2.2 },
                                        letterSpacing: {
                                            xs: "0.07em",
                                            sm: "0.17em"
                                        },
                                        display: "inline",
                                    }}>
                                    {t("そして")}&nbsp; &nbsp;
                                    {t("ひろめるをたのしもう")}
                                </Typography>
                            </SkewAnimation>
                        </Box>

                        <Box
                            my={{ xs: 1, sm: 2, md: 3, }}
                            height={isMobile ? "80px" : "120px"}
                        >
                            <FadeAndSlideAnimation
                                delay={3800}
                                in
                                css={css`
                                    width: 100%;
                                    height: 100%;
                                `}
                            >
                                <img
                                    src="/images/plus.svg"
                                    alt="plus"
                                    css={css`
                                        height: 100%;
                                    `}
                                />
                            </FadeAndSlideAnimation>
                        </Box>

                        <Box>
                            <SkewAnimation
                                delay={4000}
                                in
                            >
                                <Typography
                                    variant="h4"
                                    component="h2"
                                    sx={{
                                        letterSpacing: "0.16em",
                                        fontFamily: "Jost",
                                        display: "inline",
                                    }}>
                                    {t("We Go")}
                                </Typography>
                            </SkewAnimation>
                            <SkewAnimation
                                delay={4200}
                                in
                                css={css({
                                    margin: "0 auto"
                                })}
                            >
                                <Typography
                                    variant="h4"
                                    component="h2"
                                    sx={{
                                        letterSpacing: "0.2em",
                                        fontFamily: "Jost",
                                        display: "inline",
                                    }}
                                >
                                    {t("beyond that")}
                                </Typography>
                            </SkewAnimation>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                p={{ xs: 8, sm: 9, md: 12 }}
                sx={{
                    background: "#1f1f1f",
                    color: "white"
                }}
            >
                <SkewScrollTriggerAnimation
                    bgcolor="#1f1f1f"
                >
                    <Box>
                        <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                                letterSpacing: "0.1em",
                                fontWeight: 700,
                                fontFamily: "Jost",
                            }}
                        >
                            {t("What’s a VISUALIZ?")}
                        </Typography>
                    </Box>
                </SkewScrollTriggerAnimation>
            </Box>

            <Box
                pt={{ xs: 2, sm: 2, md: 3, lg: 4 }}>
                <SectionTitle title={t("SERVICE LINE UP")} />
            </Box>
            <ServiceCollection />

            <LinkButton
                text={t("サービス詳細はこちら")}
                href={"/service"}
            />

            <Box py={{
                xs: 2,
                sm: 4,
                md: 6
            }}>
                <NewsCollection />
            </Box>

            <Box py={{
                xs: 3
            }}>
                <BIDnaviBunner />
                <SAMMEBunner />
                <SAMMElightBunner />
            </Box>
        </main >
    );
};

const ServiceCollection = () => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
    const { t } = useTranslation();
    const items = [
        {
            to: "#",
            title: t("BIDnavi DATA"),
            detail: <>
                {t("コードを数行追加してください。")}<br />
                {t("それだけで貴社サイトは毎日新しい情報で満たされます。")}
            </>,
            service: t("DATA-API"),
            src: "/images/home_service-DATA_Tile.jpg",
            commingSoon: true,
        },
        {
            to: "/ai",
            title: t("AI"),
            detail: <>
                {t("それは例えるなら砂漠の中に点在している宝石のみを見つける仕組み。")}<br />
                {t("宝石のみを手に入れるか、仕組みそのものを手に入れるか、お望みの形でのサービスを提供します。")}
            </>,
            service: t("AI"),
            src: "/images/home_service-AI.jpg",
        },
        //        {
        //            to: "/streaming",
        //            title: t("LIVE"),
        //            detail: <>
        //                {t("課金システム搭載のLIVEシステムが定額で利用可能。")}<br />
        //                {t("未来をもっと便利に、もっと実用的に。")}
        //            </>,
        //            service: t("LIVE"),
        //            src: "/images/home_service-LIVE_Tile.jpg",
        //        },
        {
            to: "/cg-bim",
            title: t("CG ＆ BIM"),
            detail: <>
                {t("あらゆる建築業務の効率化に建築DXは欠かせません。根幹を成すBIM移行サポート。そしてフォトリアルな映像をウォークスルーアプリで提供。現実と見紛うCGがお客様に新しいUXをお届けします。")}
            </>,
            service: t("CG ＆ BIM"),
            src: "/images/home_service_CGBIM_Tile.jpg",
        },
        {
            to: "/webapp",
            title: t("WEB APP"),
            detail: <>
                {t("APPや事業収益を向上させる価値あるWEBコンテンツの構築、またそれらの運営マネジメントまで幅広くサポートいたします。")}
            </>,
            service: t("WEB APP"),
            src: "/images/home_service-WEB-APP_Tile.jpg",
        },
        {
            to: "/mirrorworld",
            title: t("META VERSE"),
            detail: <>
                {t("ミラーワールドの概念はメタバースというキーワードへと変化しました。")}
                {t("どちらにしろ目指す先にあるのは物理世界とデジタル世界の高次元での融合。「街」そのものを作り出すプラットフォームです。概要を是非ご覧ください。")}
            </>,
            service: t("MIRROR WORLD"),
            src: "/images/home_service_mirrorworld_Tile.jpg",
        }
    ];

    return (
        <>
            <Box
                mx="auto"
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    maxWidth: "1080px",
                }}
                p={{
                    xs: 1,
                    sm: 1,
                    md: 1.5,
                    lg: 2
                }}

                pt={0}
            >
                {
                    items.map((item, i) =>
                        <Box
                            key={i}
                            sx={{
                                height: {
                                    xs: "100%",
                                    sm: "50%"
                                },
                                width: {
                                    xs: "100%",
                                    sm: "50%"
                                },
                                transform: (!isMobile && i % 2 !== 0) ? "translateY(50%)" : ""
                            }}
                            p={{
                                xs: 1,
                                sm: 1,
                                md: 1.5,
                                lg: 2
                            }}
                        >
                            <ServiceItemContainer
                                to={item.to}
                                title={item.title}
                                detail={item.detail}
                                service={item.service}
                                src={item.src}
                                commingSoon={item.commingSoon}
                            />
                        </Box>
                    )}
            </Box>
            {
                !isMobile && <Box height="20rem" />
            }
        </>
    );
};

const NewsCollection = () => {
    const [t] = useTranslation();
    const [newsItems, setNewsItems] = useState<any[]>([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const { data } = await Axios.get<any>("https://visualiz-cms1.azurewebsites.net/public/sample_site/contents/news?fields=title,image,content");
        setNewsItems(data.collection);
    };

    // const newses = [
    //     {
    //         id: "6",
    //         title: "【告知】「第1回 メタバース総合展 秋」共同出典のお知らせ",
    //         link: "/news#2022.9.30",
    //         createdAt: "2022-09-30",
    //     },
    //     {
    //         id: "5",
    //         title: "【ラジオ出演】小林史明の「あなたの出番です！」",
    //         link: "/news#2022.7.18",
    //         createdAt: "2022-07-18",
    //     },
    //     {
    //         id: "4",
    //         title: "【プレゼン】福岡県田川郡添田町",
    //         link: "/news#2022.6.15",
    //         createdAt: "2022-06-15",
    //     },
    //     {
    //         id: "3",
    //         title: "【プレゼン】JR九州 古宮 社長様",
    //         link: "/news#2022.6.11",
    //         createdAt: "2022-06-11",
    //     },
    //     {
    //         id: "2",
    //         title: "【プレゼン】九州国際大学 勉強会",
    //         link: "/news#2022.4.12",
    //         createdAt: "2022-04-12",
    //     },
    //     {
    //         id: "0",
    //         title: "弊社の公式WEBサイトをリニューアルしました！",
    //         link: "/mirrorworld/#Event",
    //         createdAt: "2021-11-25",
    //     },
    //     {
    //         id: "1",
    //         title: "「Tokyo Venture Conference Frontier」に弊社代表高尾が登壇致しました。",
    //         link: "/mirrorworld/#Event",
    //         createdAt: "2021-10-27",
    //     },
    // ];
    const theme = useTheme();

    return (
        <Box
            mt={{
                xs: 3,
                sm: 4,
                md: 5
            }}
            px={3}
            pt={{
                xs: 2,
                sm: 3,
                md: 4
            }}
            pb={{
                xs: 6,
                sm: 7,
                md: 8
            }}
            css={
                css({
                    background: theme.palette.background.default,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                })
            }
        >
            <SectionTitle2
                title={t("お知らせ")}
                subTitle={t("NEWS")}
            />

            <ScrollTrigger
                once
            >
                {state => <>
                    <List
                        sx={{
                            position: "relative"
                        }}
                    >
                        {
                            newsItems.sort((a, b) => DateTime.fromISO(b.publishIn) - DateTime.fromISO(a.publishIn))
                                .map(news => (
                                    <ServiceCollectionItem
                                        key={news.contentId}
                                        cid={news.contentId}
                                        date={DateTime.fromISO(news.publishIn).toFormat("yyyy.MM.dd")}
                                        src={"https://visualiz-cms1.azurewebsites.net/public/sample_site/contents/news"}
                                        title={news.fields.title}
                                        detail={<>
                                            <div dangerouslySetInnerHTML={{
                                                __html: news.fields.content
                                            }}>
                                            </div>
                                        </>}
                                    />
                                ))
                        }

                        <Box
                            sx={{
                                position: "absolute",
                                margin: "auto",
                                right: "0px",
                                bottom: "-1rem",
                                transform: "translateY(100%)"
                            }}
                        >
                            <FadeAndSlideAnimation
                                in={state === "entered"}
                                transform={{
                                    rotate: "0deg",
                                    scale: 0.94,
                                    translate: {
                                        x: "-40px",
                                        y: "20px"
                                    }
                                }}
                                delay={300}
                            >
                                <ViewMoreButton
                                    href="/news"
                                    title="お知らせ一覧"
                                    subTitle="VIEW MORE"
                                />
                            </FadeAndSlideAnimation>
                        </Box>
                    </List>
                </>
                }
            </ScrollTrigger>        </Box>
    );
};

const ServiceCollectionItem = (
    {
        date,
        title,
        src,
        cid
    }: {
        date: string,
        src: string,
        title: string,
        cid: string
    }
) => {
    const theme = useTheme();
    return (
        <ScrollTrigger once>
            {state =>
                <FadeAndSlideAnimation
                    in={state === "entered"}

                    transform={{
                        rotate: "0deg",
                        scale: 0.94,
                        translate: {
                            x: "-40px",
                            y: "20px"
                        }
                    }}
                >
                    <ListItem >
                        <Box>
                            <Box>
                                <Link to={'/news#' + cid} css={css`text-decoration-color: black;`}>
                                    <Typography variant="caption">{date}</Typography>
                                    <Typography variant="body2" color="ButtonText">{title}</Typography>
                                </Link>
                            </Box>
                        </Box>
                    </ListItem>
                </FadeAndSlideAnimation>
            }
        </ScrollTrigger>
    );
};
